<template>
  <section>
    <div class="c-other-games-wrap">
      <img
        src="../../assets/images/img_logo_white.webp"
        alt=""
        class="c-other-games-background-logo1"
      />
      <img
        src="../../assets/images/img_logo_white.webp"
        alt=""
        class="c-other-games-background-logo2"
      />
      <div class="c-other-games-title-wrap">
        <img
          src="../../assets/images/img_slot.webp"
          alt=""
          style="width: 42px;
                 height: 42px;
                "
        />
        <div class="c-other-games-title">
          {{ $t("general.other_games") }}
        </div>
        <div class="c-other-games-separator"></div>

        <router-link
          :to="{ path: '/', query: $route.query }"
          class="c-other-games-btn"
        >
          {{ $t("general.show_all") }}
        </router-link>
      </div>
      <div class="c-swiper-games">
        <div v-for="slotGame in otherGames" :key="slotGame.name">
          <div
            :key="slotGame.name"
            class="l-home__games-grid-item"
            @click="openGame(slotGame.name)"
          >
            <app-card
              :img-path="slotGame.thumb"
              :title="slotGame.title"
              :wrapper-classes="'c-card-other-games'"
              :is-new-game="slotGame.isNew"
              :description="isScratch?$t('general.' + slotGame.description):undefined"
              :cost="slotGame.cardPrice"
              :maxwin="slotGame.diamondWin"
              :is-soon="slotGame.isSoon"
              :is-locked="isGameLocked(slotGame.name)"
              :is-unlock-price="$store.getters.getGamesGemUnlockPrice[slotGame.name]"
            />
          </div>
        </div>
      </div>
    </div>

    <!--ticket burn confirmation popup--><transition name="slide-fade-dropdown">
      <div v-if="unlockGamePopup"
           class="c-header-profile-wrap"
      >
        <div class="c-header-burn-conf">
          <div class="c-header-ticket-conf-title">
            {{ notEnoughGems?'You don\'t have enough GEMS':'Unlock '+games[clickedGame].title }}
          </div>
          <p v-if="!notEnoughGems" class="c-header-ticket-conf-text">
            {{ $filters.formatNumber( $store.getters.getGamesGemUnlockPrice[clickedGame] ) }} GEMS
          </p>
          <div class="c-header-ticket-conf-btn" @click="unlockGame(clickedGame)">
            <img :src="require('../../assets/svg-icons/icon_confirm.svg')"
                 style="fill: none"
             alt=""/>
            {{ notEnoughGems?"Return":"Confirm" }}
          </div>
          <div @click="unlockGamePopup=false">
            <img :src="require('../../assets/svg-icons/icon_close.svg')"
                 class="c-header-profile-close"
             alt=""/>
          </div>
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import AppCard from "../shared/app-card";
import { allGames, scratchCards } from "../../constants/index";

export default {
  name: "SlotsBlockchain",
  components: {
    AppCard,
  },
  data() {
    return {
      allGames,
      scratchCards,
      unlockGamePopup: false,
      clickedGame:"",
      notEnoughGems:false,
      isScratch:false,
    };
  },
  computed: {
    page(){
      return location.pathname.includes("scratch")?"scratch":"slots"
    },
    games() {
      if (
        location.hostname.includes("localhost") ||
        location.hostname.includes("test.")
      ) {
        return location.pathname.includes("scratch")?this.scratchCards:this.allGames;
      }
      let myGames = { ...this.allGames };
      for (let gameName of Object.keys(myGames)) {
        if (myGames[gameName].testing) {
          delete myGames[gameName];
        }
      }
      return myGames;
    },
    gameName: function () {
      return this.$route.params.gameId.split("-").join("");
    },
    otherGames: function () {
      let games = { ...this.allGames };
      if(location.pathname.includes("scratch"))
        games = JSON.parse(JSON.stringify(this.scratchCards));
      if (this.gameName in games) {
        delete games[this.gameName];
      }
      for (let gameName of Object.keys(games)) {
        if (games[gameName].isSoon) {
          delete games[gameName];
        }
      }
      if (
        !(
          location.hostname.includes("localhost") ||
          location.hostname.includes("test.")
        )
      ) {
        for (let gameName of Object.keys(games)) {
          if (games[gameName].testing) {
            delete games[gameName];
          }
        }
      }
      let gamesNumber =0;
      let limit = 6;
      games = this.shuffleObject(games)
      Object.keys(games).forEach(gameName=> {
        if (gamesNumber>=limit) {
          delete games[gameName];
        }else{
          gamesNumber++;
        }
      });
      return games;
    },
  },
  mounted() {
    this.isScratch = location.pathname.includes("scratch")
  },
  methods:{
    /**
     * Checks if game name is locked
     * @param {String} name game name
     * @return {Boolean}
     */
    isGameLocked(name){
      return !this.$store.getters.getUnlockedGames.includes(name);
    },

    /**
     * Open game page if game unlocked, opens unlock popup if not
     * @param {String} name game name
     */
    openGame(name){
      if(this.isGameLocked(name)){
        this.notEnoughGems = false;
        this.clickedGame=name;
        this.unlockGamePopup = true;
      } else {
        const scrollTo = document.getElementById("header");
        scrollTo.scrollIntoView();
        this.$router.push({ path: `/${this.page==='scratch'?'scratch':'game'}/${name}`, query: this.$route.query })
      }
    },

    gameUnlockPriceWithDiscount(name) {
      return Math.round(this.$store.getters.getGamesGemUnlockPrice[name] * (100-this.$store.getters.getGamesGemUnlockDiscount[name])/100);
    },

    /**
     * unlocks game if player has enough gems
     * @param {String} name game name
     */
    async unlockGame(name) {
      if(this.notEnoughGems) {
        this.notEnoughGems = false;
        this.unlockGamePopup = false;
        return;
      }
      if(this.$store.getters.getGemsBalance >= this.gameUnlockPriceWithDiscount(name)) {
        const unlock = await this.$store.dispatch("unlockGame", { game: name, price: this.gameUnlockPriceWithDiscount(name) });
        if(unlock.success) {
          this.unlockGamePopup=false;
          this.openGame(name);
        }
      } else {
        this.notEnoughGems=true;
      }
    },
    shuffleObject( obj ){
      function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]]; // Swap elements
        }
      }

      // Convert the object into an array of key-value pairs
      let arr = Object.entries(obj);

      // Shuffle the array
      shuffleArray(arr);

      // If you want to convert it back to an object
      return Object.fromEntries(arr);
    },
  }

};
</script>

<style lang="scss" scoped>
.c-other-games-wrap{
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 0 5%;

}
.c-other-games-title-wrap{
  margin: 63px auto 30px auto;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
.c-other-games-title{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 115%;
  /* or 48px */

  letter-spacing: -0.03em;
  text-transform: uppercase;

  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(95.1deg, #0CF0FF -35.4%, #EB00FF 128.43%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0 0 20px rgba(0, 209, 255, 0.2);

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;

  @include media-max(484px) {
    font-size: 24px;
    margin: auto 0;
  }
}
.c-other-games-separator{
  width: 60%;
  height: 0;

  border: 1px solid #00F0FF;
  box-shadow: 0 0 5px rgba(0, 240, 255, 0.5);
  margin-top: auto;
  margin-bottom: auto;
}
.c-other-games-btn{
  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 0;
  font-size: 16px;
  margin: auto 0;
  /* or 19px */

  text-align: center;
  letter-spacing: -0.03em;
  min-width: 57px;

  /* white */

  color: #FFFFFF;
  transition: 0.3s;
  z-index: 1;
  cursor: pointer;

  &:hover{
    color: #FFE500;
    text-shadow: 0 0 15px rgba(255, 229, 0, 0.8);
  }
}
.c-other-games-background-logo1{
  position: absolute;
  bottom: 0;
  left: -632px;
  opacity: 0.1;
  width: 1300px;
}
.c-other-games-background-logo2{
  position: absolute;
  top: 0;
  right: -542px;
  opacity: 0.1;
  width: 1300px;
}
.c-swiper-games{

  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0;
  justify-content: flex-start;

  margin: 0 auto 80px;

  @include media-max(800px) {
    overflow-x: auto;
    justify-content: left;
    width: 100%;
    @include custom-scrollbar;
  }
}

.l-other-games__slots-wrap {
  position: relative;
}

.c-swiper-games-card{
  background: transparent;
  color: white;
  width: 100%;
}


</style>
